import { getAuthToken } from "src/services/AuthService";
import { LOAD_USER } from "./userType";

const cdmCoreUrl = process.env.REACT_APP_PROD_MODE === 'true' ? process.env.REACT_APP_CDM_CORE_PROD_URL : process.env.REACT_APP_CDM_CORE_DEV_URL;
const authToken = getAuthToken();

export const loadUser = (user) => {
    return {
        type: LOAD_USER,
        payload: user
    }
}

export const getUser = () => {
    return async (dispatch) => {
        const response = await fetch(cdmCoreUrl + '/api/access/get-user-accesses', {
            headers: { Authorization: 'Bearer ' + authToken },
        });
        const data = await response.json();
        dispatch(loadUser(data))
    }
}