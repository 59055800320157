import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import userReducer from './redux/user/userReducer'
import loaderReducer from './redux/loader/loaderReducer'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  sidebarUnfoldable: sessionStorage.getItem('sidebarUnfoldable') === 'true',
  theme: 'light',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      const newState = { ...state, ...rest };
      if (newState.sidebarUnfoldable !== state.sidebarUnfoldable) {
        newState.sidebarUnfoldable = !state.sidebarUnfoldable;
        sessionStorage.setItem('sidebarUnfoldable', newState.sidebarUnfoldable);
      }
      return newState;
    default:
      return state
  }
}

const rootReducer = combineReducers({
  changeState,
  userReducer,
  loaderReducer,
})

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

export default store