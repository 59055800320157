import { LOAD_USER } from "./userType"

const userInitialState = {
    user: null
}

const userReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case LOAD_USER:
            return {
                ...state,
                user: action.payload,
            }

        default:
            return state;
    }
}

export default userReducer