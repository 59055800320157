
export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem('authToken');
};

export const checkAuthAccess = (authorizedAccessesIds, userAccessesIds) => {
  if (userAccessesIds) {
    return authorizedAccessesIds.length == 0 || userAccessesIds.some(val => authorizedAccessesIds.includes(val));
  }
  else {
    return false
  }
}