import {
    START_CONTENT_LOADER,
    STOP_CONTENT_LOADER,
    START_TOPBAR_LOADER,
    STOP_TOPBAR_LOADER
} from "./type";

const initialStateLoader = {
    contentLoaderLoading: false,
    topBarLoaderLoading: false,
}

const loaderReducer = (state = initialStateLoader, action) => {
    switch (action.type) {
        case START_CONTENT_LOADER:
            return {
                ...state,
                contentLoaderLoading: true
            }
        case STOP_CONTENT_LOADER:
            return {
                ...state,
                contentLoaderLoading: false,
            }
        case START_TOPBAR_LOADER:
            return {
                ...state,
                topBarLoaderLoading: true
            }
        case STOP_TOPBAR_LOADER:
            return {
                ...state,
                topBarLoaderLoading: false,
            }
        default:
            return state
    }
}

export default loaderReducer